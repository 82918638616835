import React, { useState } from "react";
import { Route, Routes } from 'react-router-dom'
import { ThemeProvider } from "@emotion/react"
import Cookies from 'js-cookie'

import MyState from "./context/state";

// MUI
import { Alert, Snackbar, createTheme } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles';

// PAGES
import Header from './pages/header'
import Home from './pages/home'
import Verification from "./pages/verification";
import Contact from "./pages/contact";
import CoursesList from "./pages/courses";
import VerifiedCertificate from "./pages/verify";
import InstallApp from "./pages/light-app";
import GalleryPublic from "./pages/gallery";
import SharePublic from "./pages/share";

// Controller
import AdminLogin from './login/admin/index'
import AdminReset from './login/admin/reset'
import FranchiseLogin from "./login/franchise/index";
import FranchiseReset from './login//franchise/reset'
import SidebarController from "./controller/sidebar";
import DashboardController from './controller/dashboard'
import DashboardFranchise from "./controller/dashboard-franchise";
import Franchises from "./controller/franchise";
import FranchiseUpload from "./controller/franchise-upload";
import Courses from "./controller/course";
import CourseUpload from "./controller/course-upload";
import CourseSubject from './controller/course-subject'
import Students from "./controller/student";
import StudentUpload from './controller/student-upload'
import StudentInfo from './controller/student-info'
import StudentMarks from "./controller/student-marks";
import Gallery from "./controller/gallery";
import Batch from "./controller/batch";

// User
import SidebarUser from "./student/sidebar";

// Payment
import Payment from "./controller/payment";

// Attendance
import AttendanceFranchise from "./controller/attendance";
import Scanner from "./controller/attendance/scanner";
import IDCard from "./controller/attendance/idcard";

// PUBLIC
import ComputerCertificate from './public/certificate'
import TypingCertificate from './public/typing-certificate'
import ComputerMarksheet from './public/marksheet'

// Student
import AdmissionStudent from "./pages/admission";
import StudentHome from "./student";
import StudentLogin from "./login/student";
import StudentReset from "./login/student/reset";
import PaymentStudent from "./student/payment";
import AttendanceStudent from "./student/attendance";


function App() {

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1300,
        xl: 1920
      },
    },
    palette: {
      primary: {
        light: '#8537CA',
        main: '#6F2DA8',
        dark: '#301349',
        contrastText: '#FFFFFF',
      },
      secondary: {
        main: '#FFD700',
      },
      error: {
        main: '#FF0800'
      },
      info: {
        main: '#007FFF'
      }
    },
    typography: {
      button: {
        textTransform: 'initial'
      },
    },

    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none'
          }
        }
      }
    }
  })

  const fullscreen = useMediaQuery(useTheme().breakpoints.down('md'));

  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const handlesnackbar = (data) => {
    setMessage(data); setOpen(true)
  }

  const auth = Cookies.get('authuser')

// APIS
  const apilogin = '/vidyapeethassets/api/login/index.php'
  const apiadmin = '/vidyapeethassets/api/admin/index.php'
  const apiglobal = '/vidyapeethassets/api/global/index.php'
  const apipublic = '/vidyapeethassets/api/public/index.php'

  const stuimg = '/vidyapeethassets/student/'
  const gallery = '/vidyapeethassets/gallery/'

  return (
    <MyState>
    <ThemeProvider theme={theme} >
      <Snackbar open={open} onClose={() => setOpen(false)} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Alert severity="warning" variant="filled" icon={false} onClose={() => setOpen(false)} >{message}</Alert>
      </Snackbar>
      <Routes>
        <Route path="/login-admin" element={<AdminLogin apiadminlogin={apilogin} snackbar={handlesnackbar} />} />
        <Route path="/reset-admin" element={<AdminReset apilogin={apilogin} snackbar={handlesnackbar} />} />
        <Route path="/login-student" element={<StudentLogin snackbar={handlesnackbar} />} />

        <Route path="/login-franchise" element={<FranchiseLogin apilogin={apilogin} snackbar={handlesnackbar} />} />
        <Route path="/reset-franchise" element={<FranchiseReset apilogin = {apilogin} snackbar={handlesnackbar} />} />
        <Route path="/reset-student" element={<StudentReset snackbar={handlesnackbar} />} />
        <Route path="/light-app" element={<InstallApp />} />
        
        <Route path="/" element={<Header />}>
          <Route path="/" element={<Home snackbar={handlesnackbar} />} />
          <Route path="/verification" element={<Verification apipublic={apipublic} snackbar={handlesnackbar} />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/courses" element={<CoursesList />} />
          <Route path="/verify" element={<VerifiedCertificate apipublic={apipublic} snackbar={handlesnackbar} stuimg={stuimg} />} />
          <Route path="/gallery" element={<GalleryPublic apipublic={apipublic} gallery={gallery} />} />
          <Route path="/share" element={<SharePublic />} />

          {/* Student */}
          <Route path="admission" element={<AdmissionStudent snackbar={handlesnackbar} />} />
        </Route>

        <Route path="/public/computer-certificate/:studentid/:courseid" element={<ComputerCertificate apipublic={apipublic} />} />
        <Route path="/public/computer-marksheet/:studentid/:courseid" element={<ComputerMarksheet apipublic={apipublic} />} />
        <Route path="/public/typing-certificate/:studentid/:courseid" element={<TypingCertificate apipublic={apipublic} />} />

        <Route path='/controller' element={<SidebarController apiglobal={apiglobal} />}>
          <Route path="dashboard" element={<DashboardController apiadmin={apiadmin} />} />
          <Route path="dashboard-franchise" element={<DashboardFranchise apiglobal={apiglobal} />} />
          <Route path="franchises/*" element={<Franchises snackbar={handlesnackbar} apiadmin={apiadmin} />} />
          <Route path="franchise-upload/:franchiseid" element={<FranchiseUpload snackbar={handlesnackbar} apiadmin={apiadmin} />} />
          <Route path="courses" element={<Courses apiadmin={apiadmin} />} />
          <Route path="course-upload/:courseid" element={<CourseUpload apiadmin={apiadmin} snackbar={handlesnackbar} />} />
          <Route path="course-subject/:courseid" element={<CourseSubject apiadmin={apiadmin} snackbar={handlesnackbar} />} />
          <Route path="students" element={<Students auth={auth} apiglobal={apiglobal} />} />
          <Route path="student-upload/:studentid" element={<StudentUpload auth={auth} apiglobal={apiglobal} apiadmin={apiadmin} snackbar={handlesnackbar} />} />
          <Route path="student-info/:studentid" element={<StudentInfo apiadmin={apiadmin} apiglobal={apiglobal} snackbar={handlesnackbar} auth={auth} fullscreen={fullscreen} />} />
          <Route path="student-marks/:studentid/:courseid" element={<StudentMarks apiglobal={apiglobal} snackbar={handlesnackbar} auth={auth} />} />
          <Route path="gallery" element={<Gallery apiadmin={apiadmin} snackbar={handlesnackbar} gallery={gallery} />} />
          <Route path="batch" element={<Batch />} />

          {/* Attendance */}
          <Route path="attendance" element={<AttendanceFranchise apiglobal={apiglobal} />} />

          {/* Payment */}
          <Route path="payment" element={<Payment snackbar={handlesnackbar} />} />

        </Route>

        {/* Student */}
        <Route path="/student" element={<SidebarUser />}>
          <Route path="home" element={<StudentHome snackbar={handlesnackbar} />} />
          <Route path="payment" element={<PaymentStudent />} />
          <Route path="attendance" element={<AttendanceStudent />} />
        </Route>

        {/* Attendance */ }
        <Route path="/attendance/scanner" element={<Scanner />} />
        <Route path="/idcard/:studentid/:courseid" element={<IDCard />} />
        
      </Routes>
    </ThemeProvider>
    </MyState>
  );
}

export default App;
