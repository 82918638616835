import React, { useContext, useEffect, useState } from "react";
import {  } from 'react-router-dom'

import MyContext from "../context/context";

// MUI
import { Alert, Box, Card, CardContent, Chip, Container, Divider, Stack, Typography } from '@mui/material'
import { CurrencyRupee } from '@mui/icons-material'
import axios from "axios";

export default function PaymentStudent(){
    const api = useContext(MyContext)
    useEffect(() => {
        Payment()
    }, [])

    const [pay, setPay] = useState([])
    const Payment = () => {
        axios.get(`${api.api.student}?my-dashboard`)
        .then(function(res){
            if(res.data.status !== false){
                if(res.data.pay.status === true){
                    setPay(res.data.pay.data)
                }
            }
        })
    }

    const [payhis, setPayhis] = useState([])

    useEffect(() => {
        axios.get(`${api.api.student}?payment-history`)
        .then(function(res){
            if(res.data.status === false){
                setPayhis(res.data)
            } else {
                setPayhis(res.data.data)
            }
        })
    }, [])

    return(
        <Box className='content' >
            <Container>
                <Card>
                    <CardContent>
                        <Typography mb={2} fontSize={20} fontWeight={600} color={`info.main`} >Payment</Typography>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Fees{parseInt(pay.fees) > 0 && '/Discount'}</Typography>
                            <Stack direction={'row'} spacing={0.5} >
                                <Chip icon={<CurrencyRupee/>} label={`${pay.fees > 0 && parseInt(pay.fees).toLocaleString('en-in')}`} variant="outlined" color="error" />
                                {parseInt(pay.fees) > 0 && <Chip icon={<CurrencyRupee/>} label={parseInt(pay.discount).toLocaleString('en-in')} variant="outlined" color="error" /> }
                            </Stack>
                        </Stack><Divider/>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Payable amount</Typography>
                            <Chip label={parseInt(parseInt(pay.fees)-parseInt(pay.discount)).toLocaleString('en-in')} color="success" icon={<CurrencyRupee/>} />
                        </Stack><Divider/>
                        <Stack mt={1} mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Payed amount</Typography>
                            <Chip label={parseInt(pay.payed).toLocaleString('en-in')} color="info" icon={<CurrencyRupee/>} />
                        </Stack><Divider/>
                        <Stack mt={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Left payment</Typography>
                            <Chip label={parseInt(parseInt(pay.fees)-parseInt(pay.discount)-parseInt(pay.payed)).toLocaleString('en-in')} color="warning" icon={<CurrencyRupee/>} />
                        </Stack>
                    </CardContent>
                </Card>

                <Typography fontSize={20} color={'info.main'} fontWeight={600} mt={3} mb={1.5} >Payment History</Typography>
                {payhis.status === false ? <Alert severity="warning">{payhis.msg}</Alert> : payhis.map((row, i) =>
                <Card sx={{mt: 1}} key={i} >
                    <CardContent>
                        <Stack mb={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={18} fontWeight={500} >Payed Amount</Typography>
                            <Chip label={parseInt(row.amount).toLocaleString('en-in')} color="info" icon={<CurrencyRupee/>} />
                        </Stack><Divider />
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Date</Typography>
                            <Typography fontSize={14} color={'gray'} mt={1} >{new Date(row.p_date).toLocaleDateString(undefined, {day: '2-digit', month: 'long', year: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true})}</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Description</Typography>
                            <Typography fontSize={14} color={'gray'} mt={1} >{row.description}</Typography>
                        </Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={14} color={'gray'} >Payment ID</Typography>
                            <Typography fontSize={14} fontWeight={500} color={'warning.main'} mt={1} >{row.paymentid}</Typography>
                        </Stack>
                    </CardContent>
                </Card> ) }
            </Container>
        </Box>
    );
}