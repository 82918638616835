import MyContext from "./context";
import Cookies from 'js-cookie'

const MyState = (props) => {

    const auth = Cookies.get('authuser')
    
    const api = {
        "admin" : "/vidyapeethassets/api/admin/index",
        "global" : "/vidyapeethassets/api/global/index",
        "public" : "/vidyapeethassets/api/public/index",
        "student" : "/vidyapeethassets/api/student/index",
        "login" : "/vidyapeethassets/api/login/index",
        "stuimg" : "/vidyapeethassets/student/",
        "gallery" : "/vidyapeethassets/gallery/"
    }

    return (
        <MyContext.Provider value={{api, auth}} >
            {props.children}
        </MyContext.Provider>
    );
}
export default MyState