import React from "react";
import { NavLink, useNavigate } from 'react-router-dom'

// MUI
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { Call, Facebook, Instagram, WhatsApp } from '@mui/icons-material'

export default function Footer(){
    const navi = useNavigate()

    return(
        <Box sx={{bgcolor: '#2A3439'}} pb={3} >
            <Box sx={{bgcolor: 'primary.main'}} padding={2} mb={3} textAlign={'center'} >
                <Typography fontSize={25} color={'white'} fontWeight={'bold'} fontFamily={'initial'} >VIDYAPEETH COMPUTER EDUCATION</Typography>
            </Box>
            <Container>
                <Stack direction={{xs: 'column', md: 'row'}} pb={3} sx={{borderBottom: 'thin solid', borderColor: 'secondary.main'}} justifyContent={'space-between'} spacing={3} >
                    <Box>
                        <Typography fontSize={16} color={'secondary.main'} fontWeight={'bold'} letterSpacing={2} >SITE MAP</Typography>
                        <Stack direction={'row'} spacing={2} useFlexGap flexWrap={'wrap'} mt={0.5} >
                            <NavLink to={'/'} style={{color: 'white'}} >Home</NavLink>
                            <NavLink to={'/'} style={{color: 'white'}} >Verification</NavLink>
                            <NavLink to={'/'} style={{color: 'white'}} >Courses</NavLink>
                            <NavLink to={'/'} style={{color: 'white'}} >Contact</NavLink>
                        </Stack>

                        <Typography fontSize={16} color={'secondary.main'} fontWeight={'bold'} letterSpacing={2} mt={3} >LOGIN</Typography>
                        <Stack direction={'row'} spacing={2} useFlexGap flexWrap={'wrap'} mt={0.5} >
                            <NavLink style={{color: 'white'}} to={'/login-admin'} >Admin Login</NavLink>
                            <NavLink style={{color: 'white'}} to={'/login-franchise'} >Franchise Login</NavLink>
                        </Stack>

                        <Typography fontSize={16} color={'secondary.main'} fontWeight={'bold'} letterSpacing={2} mt={3} >Get in touch with us</Typography>
                        <Stack direction={'row'} spacing={2} useFlexGap flexWrap={'wrap'} mt={0.5} >
                            <Button variant="outlined" color="error" startIcon={<Facebook/>} onClick={() => window.location.href = 'https://www.facebook.com/vidyapeethcomputeracademy?mibextid=qi2Omg&rdid=MyLALbGAqyQIrc8g'} >Facebook</Button>
                            <Button variant="outlined" color="error" startIcon={<Instagram/>} onClick={() => window.location.href="https://www.instagram.com/vidyapeethcomputeracademy?igsh=ZXJoeGJpZXN6eDBr"} >Instagram</Button>
                            <Button variant="outlined" color="error" startIcon={<WhatsApp/>} onClick={() => window.location.href = '#'} >WhatsApp</Button>
                            <Button variant="outlined" color="error" startIcon={<Call/>} onClick={() => window.location.href = 'tel: 06424296255'} >Call</Button>
                        </Stack>
                    </Box>
                    <Box textAlign={{md: 'right', xs: 'left'}} >
                        <Typography fontSize={18} fontWeight={'bold'} color={'secondary.main'} mb={1} >Take a walk to our office</Typography>
                        <Typography color={'white'} >Samukhiya More, Banka - 813102</Typography>
                        <Typography color={'white'} >Bihar, India</Typography>

                        <Typography color={'secondary.main'} mt={3} mb={1} >Join us to learn new technology!</Typography>
                        <Button variant="contained" color="error" sx={{borderRadius: 0, fontWeight: 'bold'}} onClick={() => navi(`/admission`)} >Join Vidyapeeth</Button>
                    </Box>
                </Stack>
                <Stack direction={{xs: 'column', md: 'row'}} alignItems={{md: 'center', xs: 'start'}} justifyContent={'space-between'} mt={3} spacing={2} >
                    <Typography color={'white'} fontSize={14} >&#169; copyright vidyapeeth computer education. All rights reserved.</Typography>
                    <a href="https://passionfrom.com" target="_blank"><img src="https://passionfrom.com/passionfromassets/whitePassionfrom.png" style={{width: 120}} /></a>
                </Stack>
            </Container>
        </Box>
    );
}