import React, { useState } from "react";
import {  } from 'react-router-dom'
import Footer from "./footer";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// MUI
import { Box, Button, Card, CardContent, CardMedia, Container, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { AutoFixHigh, Call, Instagram, Mail, PrecisionManufacturing, Recommend, Star, Style, WhatsApp, YouTube } from '@mui/icons-material'

export default function Home(){

    return(
        <><Box pb={3} >

            <Box>
                <Swiper
                    autoHeight={true}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <img src="/images/carousel1.jpg" style={{height: '600px', objectFit: 'cover'}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/carousel2.jpg" style={{height: '600px', objectFit: 'cover'}} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img src="/images/carousel3.jpg" style={{height: '600px', objectFit: 'cover'}} />
                    </SwiperSlide>
                </Swiper>
            </Box>
            
            <Container>
                <Grid mt={3} container sx={{backgroundColor: 'secondary.main', borderRadius: 5}} >
                    <Grid item xs={12} md={9} mx={'auto'} p={3} pt={5} pb={5} >
                        <Typography textAlign={'center'} fontSize={24} >
                            At Vidyapeeth, we are dedicated to shaping the future of technology by providing top-tier education and training in computer science, IT, and digital skills. Whether you're a beginner looking to enter the world of technology or a professional seeking to enhance your expertise, our comprehensive programs cater to all levels and aspirations.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container mt={10} spacing={2} >
                    <Grid item xs={12} mb={2} >
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={20} fontWeight={600} >
                                Why choose us
                            </Typography>
                            <Button variant="outlined" color="primary" >Explore courses</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Box>
                                <PrecisionManufacturing sx={{width: 100, height: 100, color: 'primary.main'}} />
                            </Box>
                            <Box p={2} >
                                <Typography fontSize={18} fontWeight={600}>
                                Industry-Driven Curriculum
                                </Typography>
                                <Typography fontSize={12} color={'gray'} >
                                Our courses are meticulously crafted to align with the latest industry trends and demands, ensuring that you graduate with skills that are relevant and in-demand.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Box>
                                <Star sx={{width: 100, height: 100, color: 'primary.main'}} />
                            </Box>
                            <Box p={2} >
                                <Typography fontSize={18} fontWeight={600}>
                                Expert Faculty
                                </Typography>
                                <Typography fontSize={12} color={'gray'} >
                                Learn from seasoned professionals and experts in the field who bring real-world experience and insights into the classroom, enriching your learning journey.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Paper>
                            <Box>
                                <AutoFixHigh sx={{width: 100, height: 100, color: 'primary.main'}} />
                            </Box>
                            <Box p={2} >
                                <Typography fontSize={18} fontWeight={600}>
                                State-of-the-Art Facilities
                                </Typography>
                                <Typography fontSize={12} color={'gray'} >
                                Experience hands-on learning in our cutting-edge labs equipped with the latest hardware and software, providing you with an immersive and practical learning environment.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Paper>
                            <Box>
                                <Style sx={{width: 100, height: 100, color: 'primary.main'}} />
                            </Box>
                            <Box p={2} >
                                <Typography fontSize={18} fontWeight={600}>
                                Flexible Learning Options
                                </Typography>
                                <Typography fontSize={12} color={'gray'} >
                                Whether you prefer traditional classroom settings or the flexibility of online learning, we offer a range of options to suit your schedule and preferences.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper>
                            <Box>
                                <Recommend sx={{width: 100, height: 100, color: 'primary.main'}} />
                            </Box>
                            <Box p={2} >
                                <Typography fontSize={18} fontWeight={600}>
                                Career Support
                                </Typography>
                                <Typography fontSize={12} color={'gray'} >
                                Our dedicated career services team provides guidance and assistance with job placement, internships, and interview preparation, empowering you to launch or advance your career in the tech industry.
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>

                <Box mt={10} pt={7} pb={7} borderRadius={5} sx={{backgroundImage: "linear-gradient(90deg, #ff930f, #fff95b)"}} >
                    <Typography fontSize={35} fontWeight={600} textAlign={'center'}>
                    Empowering Possibilities, One Byte at a Time
                    </Typography>
                    <Typography fontSize={35} fontWeight={600} textAlign={'center'} >
                    Where Dreams Meet Digital Destiny!
                    </Typography>
                </Box>

                <Grid container spacing={2} >
                    <Grid item xs={12} mt={10} >
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography fontSize={20} fontWeight={600} >
                                Most popular courses
                            </Typography>
                            <Button variant="outlined" color="primary" >Explore courses</Button>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    C Programming
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap >
                                    General purpose programming language
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    C++ Programming
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Create high performance applications
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    Python programming
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Built high performance websites & applications
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    Website development
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Built interective website
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    Android development
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Built user friendly android application
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    DCA
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Diploma in Computer Application
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    ADCA
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Advanced Diploma in Computer Application
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={3} mt={3} >
                        <Card>
                            <CardMedia
                            image="/images/img4.jpg"
                            sx={{height: 140}} />
                            <Divider/>
                            <CardContent>
                                <Typography fontSize={25} fontWeight={800}>
                                    Tally prime
                                </Typography>
                                <Typography fontSize={14} color={'gray'} noWrap>
                                    Manage your business with Tally prime
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Grid container mt={10} pt={7} pb={7} borderRadius={5} sx={{border: `thin solid #ff930f` }} >
                    <Grid item xs={12} md={8} textAlign={'center'} mx={'auto'} >
                        <Typography fontSize={35} fontWeight={600} textAlign={'center'}>
                        Get Started Today!
                        </Typography>
                        <Typography mt={2} fontSize={16} fontWeight={400} textAlign={'center'} >
                        Ready to embark on your journey towards a successful career in technology? Browse our programs, explore our resources, and take the first step towards a brighter future with Vidyapeeth Computer Education.
                        </Typography>
                        <Stack direction={'row'} spacing={1} justifyContent={'center'} mt={2} >
                            <Button variant="contained" color="secondary" startIcon={<Call />} onClick={() => window.location.href="tel: 06424296255"} >CALL</Button>
                            <Button variant="contained" color="secondary" startIcon={<Mail />} >E-MAIL</Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
            

        </Box><Footer/></>
    );
}