import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'

// MUI
import { Box, Button, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, Input, InputAdornment, InputLabel, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { CloudUpload, Delete, Edit, Search } from '@mui/icons-material'

import MyContext from "../context/context";
import axios from "axios";

export default function Payment({snackbar}){
    const api = useContext(MyContext)
    const navi = useNavigate()
    const [open, setOpen] = useState('')
    const [action, setAction] = useState('')

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`

    var url = new URLSearchParams(window.location.search).get('value')
    const [searchquery, setSearchquery] = useState(url?url:'')

    useEffect(() => {
        getStudents()
    }, [])
    const [students, setStudents] = useState([])
    const [loading, setLoading] = useState(true)
    const getStudents = () => {
        var formData = new FormData()
        formData.append('get-students', searchquery)
        fetch(api.api.global, {
            method: 'post',
            body: formData
        }).then(res => res.json())
        .then(data => {
            setLoading(false)
            if(data.status === false){
                setStudents(data)
            } else {
                setStudents(data.data)
            }
        })
    }

    const search_student = () => {
        navi(`?value=${searchquery}`, {replace: true})
        getStudents()
    }

    const [studentid, setStudentid] = useState('')
    const [amount, setAmount] = useState('')
    const [pday, setPday] = useState(formattedDate)

    const [fees, setFees] = useState('')
    const [discount, setDiscount] = useState('')
    const open_fee = (studentid) => {
        setFees(''); setDiscount(''); setAmount('')
        payment_history(studentid)
        setStudentid(studentid)
        axios.get(`${api.api.global}?get-fees-details=${studentid}`)
        .then(function(res){
            snackbar(res.data.msg)
            if(res.data.status === true){
                setFees(res.data.data.fees)
                setDiscount(res.data.data.discount)
            }
        })
        setOpen('openfee')
    }

    const update_fees = () => {
        setAction('updating')
        var formData = new FormData()
        formData.append('update-student-fees', studentid)
        formData.append('fees', fees)
        formData.append('discount', discount)
        axios.post(api.api.global, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)

        })
    }

    const [phistory, setPhistory] = useState([])
    const payment_history = (studentid) => {
        axios.get(`${api.api.global}?payment-history=${studentid}`)
        .then(function(res){
            snackbar(res.data.msg)
            if(res.data.status === false){
                setPhistory(res.data)
            } else {
                setPhistory(res.data.data)
            }
        })
    }

    const update_payment = () => {
        setAction('updating-payment')
        var formData = new FormData()
        formData.append('update-payment', studentid)
        formData.append('date', pday)
        formData.append('amount', amount)
        axios.post(api.api.global, formData)
        .then(function(res){
            setAction('')
            snackbar(res.data.msg)
            payment_history(studentid)
        })
    }

    const [opendel, setOpendel] = useState(false)
    const [paymentid, setPaymentid] = useState('')
    const open_delete = (paymentid) => {
        setOpendel(true)
        setPaymentid(paymentid)
    }
    const delete_payment = () => {
        var formData = new FormData()
        formData.append('delete-payment', studentid)
        formData.append('paymentid', paymentid)
        formData.append('code', action)
        axios.post(api.api.global, formData)
        .then(function(res){
            snackbar(res.data.msg)
            if(res.data.status === true){
                setOpendel(false)
                payment_history(studentid)
            }
        })
    }

    return(
        <Box className='content' >
            <Dialog open={opendel} fullWidth maxWidth='xs' onClose={() => setOpendel(false)} >
                <DialogContent>
                    <Typography fontWeight={'bold'} fontSize={25} color={'darkorange'} >Do you want to delete this payment?</Typography>
                    <Typography fontSize={14} color={'gray'} >Remember, you can't undo this process.</Typography>

                    <Typography fontSize={14} color={'gray'} mt={2} >To delete this payment, enter your security code here.</Typography>
                    <TextField fullWidth variant="outlined" type="text" size="small" placeholder="Security code" value={action} onChange={(event) => setAction(event.target.value)} onKeyUp={(event) => event.key === 'Enter' && delete_payment()} />
                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpendel(false)} >Close</Button>
                    <Button variant="contained" color="error" disabled={action === 'deleting' ? true : false} onClick={() => delete_payment()} >{action === 'deleting' ? <CircularProgress color="inherit" size={24} /> : 'Delete'}</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={open === 'openfee' ? true : false} fullWidth maxWidth='md' onClose={() => setOpen('')} >
                <DialogTitle>Payment</DialogTitle><Divider/>
                <DialogContent>
                    <Typography fontSize={16} fontWeight={600} color={'info.main'} >Payment fee</Typography>
                    <Stack mb={2} direction={{xs: 'column', md: 'row'}} mt={1} spacing={0.5} >
                        <TextField variant="outlined" label='Course fees' fullWidth size="small" type="number" value={fees} onChange={(event) => setFees(event.target.value)} />
                        <TextField variant="outlined" label='Discount' fullWidth size="small" type="number" value={discount} onChange={(event) => setDiscount(event.target.value)} />
                        <Button variant="outlined" color="error" sx={{width: 200}} onClick={() => update_fees()} disabled={action === 'updating' ? true : false} >{action === 'updating' ? <CircularProgress color="inherit" size={24} /> : 'Update'}</Button>
                    </Stack>
                    <Stack direction={'row'} spacing={0.5} mb={2} >
                        <TextField fullWidth variant="outlined" size="small" label='Payable amount' disabled value={fees-discount} />
                        <TextField fullWidth variant="outlined" size="small" label='Payed amount' disabled value={phistory.length > 0 && phistory.reduce((total, i) => total + Number((i.amount)), 0)} />
                    </Stack>
                    <Stack direction={'row'} spacing={0.5} mb={2} >
                        <TextField fullWidth variant="outlined" size="small" label='Left payment' disabled value={(fees-discount) - parseInt(phistory.length > 0 && phistory.reduce((total, i) => total + Number((i.amount)), 0))} />
                    </Stack>

                    <TableContainer component={Paper} >
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={2} >
                            <Typography fontSize={16} fontWeight={600} color={'info.main'} >Payable amount</Typography>

                            <Stack direction={'row'} spacing={0.5} >
                                <TextField variant="outlined" size="small" type="date" value={pday} onChange={(event) => setPday(event.target.value)} />
                                <TextField variant="outlined" size="small" type="number" label='Amount' value={amount} onChange={(event) => setAmount(event.target.value)} />
                                <IconButton color="info" onClick={() => update_payment()} >{action === 'updating-payment' ? <CircularProgress color="inherit" size={24} /> : <CloudUpload />}</IconButton>
                            </Stack>
                        </Stack> <Divider/>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell align="left">Name</TableCell>
                                    <TableCell align="left">Date</TableCell>
                                    <TableCell align="left">Amount</TableCell>
                                    <TableCell align="right">Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { phistory.status !== false && phistory.map((row, i) =>
                                <TableRow key={i} >
                                    <TableCell>{(phistory.length)-i}</TableCell>
                                    <TableCell align="left">{row.name}</TableCell>
                                    <TableCell align="left">{new Date(row.p_date).toLocaleDateString(undefined, {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute:'2-digit', hourCycle: 'h12'})}</TableCell>
                                    <TableCell align="left">&#8377; {(row.amount).toLocaleString('en-in')}</TableCell>
                                    <TableCell align="right" ><IconButton color="error" onClick={() => open_delete(row.paymentid)} ><Delete /></IconButton></TableCell>
                                </TableRow> ) }
                            </TableBody>
                        </Table>
                    </TableContainer>

                </DialogContent><Divider/>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setOpen('')} >Close</Button>
                </DialogActions>
            </Dialog>
            <Container>

                <FormControl variant="standard" fullWidth>
                    <InputLabel>Search student by name, mobile</InputLabel>
                    <Input
                        value={searchquery}
                        onChange={(event) => setSearchquery(event.target.value)}
                        type='text'
                        onKeyUp={(event) => searchquery.length > 0 && event.key === 'Enter' && search_student()}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton disabled={searchquery.length > 0 ? false : true} onClick={() => search_student()} ><Search/></IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>

                <TableContainer component={Paper} sx={{mt: 2, height: {md: 530, xs: 'auto'}}} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Roll no.</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="right">Edit</TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            { students.status !== false && students.map((row, i) =>
                            <TableRow key={i} >
                                <TableCell align="left">{row.rollno}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">
                                    <Chip label={row.status} color={row.status === 'active' ? 'success' : row.status === 'pending' ? 'warning' : 'info'} />
                                </TableCell>
                                <TableCell align="right">
                                    <Chip label='Payment' clickable color="error" onClick={() => open_fee(row.studentid)} icon={<Edit />} />
                                </TableCell>
                            </TableRow> ) }
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>
        </Box>
    );
}