import React, { useState } from "react";
import { NavLink, Outlet, useNavigate } from 'react-router-dom'

// MUI
import { AppBar, Box, Button, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar } from '@mui/material'
import { Shortcut, Menu } from '@mui/icons-material'

export default function Header(){
    let navi= useNavigate()
    const [action, setAction] = useState('')

    const links = [
        {name: 'Home', link: '/'},
        {name: 'Verification', link: '/'},
        {name: 'Contact', link: '/'}
    ]

    const goto = (link) => {
        navi(link)
        setAction('')
    }

    return(
        <Box>
            <AppBar color="primary" position="sticky" elevation={0} >
                <Toolbar sx={{mt: 0.5}} >
                    <Box sx={{flexGrow: 1, textAlign: 'left'}}>
                        <img src="/logo_text.png" alt="Vidyapeeth Computer Education Logo" style={{borderRadius: 0, width: '200px'}} />
                    </Box>
                    
                    <Stack spacing={2} direction='row' alignItems={'center'} sx={{display: {xs: 'none', md: 'flex'}}} >
                        { links.map((row, i) =>
                        <NavLink to={row.link} style={({isActive}) => { return { color: isActive ? '#FFFFFF' : '#FFFFFF', fontWeight: 400 } }} key={i} >{row.name}</NavLink> ) }
                        <NavLink>
                            <Button color="secondary" variant="contained" >Course</Button>
                        </NavLink>
                    </Stack>
                    <IconButton sx={{display: {xs: 'flex', md: 'none'}}} edge='end' color="secondary" onClick={() => setAction('opendraw')} >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer open={action === 'opendraw' ? true : false} onClose={() => setAction('')} anchor="bottom" >
                <List>
                    { links.map((row) =>
                    <ListItem key={row.link} >
                        <ListItemButton onClick={() => goto(row.link)} >
                            <ListItemIcon><Shortcut/></ListItemIcon>
                            <ListItemText >{row.name}</ListItemText>
                        </ListItemButton>
                    </ListItem> ) }
                    <ListItem>
                        <ListItemButton>
                            <ListItemIcon><Shortcut/></ListItemIcon>
                            <ListItemText>Course</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>

            <Outlet/>
        </Box>
    );
}